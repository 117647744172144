import React, { useState, useMemo, useEffect } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import PostThumbnails from '../../components/BlogContent/PostThumbnails';
import Layout from '../../components/layout';
import { callTrackingApiUrl } from '../../configs';

const PostPerPage = 6;
const BlogId = '8365748950224941809';
const Key = 'AIzaSyByw3Nxi7tcP96PQVUgIN3xWcF0n9XBerM';

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const [isBannersShown, setIsBannersShown] = useState(false);
  const [ipAction, setIpAction] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(PostPerPage);

  //Get current posts
  const currentPosts = useMemo(() => {
    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    return posts.slice(indexOfFirstPost, indexOfLastPost);
  }, [currentPage, posts, postPerPage]);

  const fetchPosts = async () => {
    const url = `https://www.googleapis.com/blogger/v3/blogs/${BlogId}/posts?key=${Key}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        'content-Type': 'application/json'
      }
    };
    setLoading(true);
    await fetch(url, requestOptions)
      .then(res => res.json())
      .then(res => {
        setPosts(prevPosts => [...prevPosts, ...res.items]);
        setLoading(false);
      });
  };

  const fetchBannerDetials = async () => {
    const response = await fetch(`${callTrackingApiUrl}/call-tracking/banner`);
    const responseJson = await response.json();

    setIsBannersShown(responseJson.data.show);
    setIpAction(responseJson.data.ipAction);
  };

  useEffect(() => {
    fetchPosts();
    fetchBannerDetials();
  }, []);

  return (
    <Layout
      isStaticPage
      passPhoneNumber={num => num}
      isBannersShown={isBannersShown}
      ipAction={ipAction}
    >
      {loading ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        ''
      )}
      <PostThumbnails posts={currentPosts} />
      <Pagination
        currentPage={currentPage}
        totalCards={posts.length}
        cardsPerPage={postPerPage}
        paginate={page => {
          setCurrentPage(page);
          // scroll to top when page is changed
          window.scroll({
            top: 0,
            behavior: 'smooth'
          });
        }}
      />
    </Layout>
  );
};

export default BlogPage;
