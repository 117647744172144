import React from 'react';
import './PostThumbnails.css';
import travelopodImg from '../../images/travelopod-logo.png';

const setPostLink = post => {
  sessionStorage.setItem('currentBlogPost', post.selfLink);
};

const parseImages = html => {
  let imgList = [];
  var parser = new DOMParser();
  var content = parser.parseFromString(html, 'text/html');
  var imgArray = content.getElementsByTagName('img');

  for (let i = 0; i < imgArray.length; i++) {
    imgList.push(imgArray[i].src);
  }

  return imgList.length ? imgList : [travelopodImg];
};

const PostThumbnails = ({ posts }) => {
  return (
    <div className="blog-container">
      <div className="articles-list">
        {posts &&
          posts.map(post => {
            const postImages = parseImages(post.content);
            const postContent = post.content.replace(/<\/?[^>]+(>|$)/g, '');
            return (
              <div key={post.id} className="article">
                <div className="article-image">
                  <img
                    src={postImages[0]}
                    alt={post.title}
                    onError={e => {
                      e.target.src = travelopodImg;
                    }}
                  />
                </div>
                <div className="article-detail">
                  <div className="article-detail__title">
                    <a
                      href={`/blog/post#${post.id}`}
                      onClick={() => setPostLink(post)}
                    >
                      <h2>{post.title}</h2>
                    </a>
                  </div>
                  <div className="article-detail__author">
                    <span className="author">by {post.author.displayName}</span>
                    <br />
                    <span className="date">
                      {new Date(post.published)
                        .toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric'
                        })
                        .replace(/ /g, '-')}
                    </span>
                  </div>
                  <div className="article-details__body">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: postContent
                      }}
                    />
                    <a
                      href={`/blog/post#${post.id}`}
                      className="read-more"
                      onClick={() => setPostLink(post)}
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PostThumbnails;
